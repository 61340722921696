<template>
    <LiefengContent>
        <template #title>账号注销管理</template>
        <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" :label-width="80">
                <FormItem label="姓名">
                    <Input type="text" v-model="search.userName" style="width:200px"></Input>
                </FormItem>
                <FormItem label="账号">
                    <Input type="text" v-model="search.mobile" style="width:200px"></Input>
                </FormItem>
                <FormItem label="注销状态"   >
                    <Select transfer v-model="search.status" style="width:200px">
                        <Option   value="全部">全部</Option >
                        <Option   value="1">待注销</Option >
                        <Option   value="3">已注销</Option >
                    </Select>
                </FormItem>
                <Button style="margin-right:10px" type="primary" icon="ios-search" @click="searchBtn" >查询</Button>
                <Button style="margin-right:10px" type="success" icon="ios-refresh" @click="resetBtn" >重置</Button>
            </Form> 
        </template>
        <template #contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            search:{
                userName:'',
                mobile:'',
                status:'全部'
            },

            tableData: [],
            talbeColumns: [
                {
                    title: "姓名",
                    key: "userName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    width: 80,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 1 ? '男': params.row.sex ==2 ? '女':'')
                    },
                },
                {
                    title: "账号",
                    key: "mobile",
                    minWidth: 140,
                    align: "center",
                },

                {
                    title: "申请提交时间",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "停留工作日",
                    key: "stayWorkDays",
                    minWidth: 140,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.stayWorkDays?`${params.row.stayWorkDays}天`:'—— 天')
                    },
                },
                {
                    title: "注销状态",
                    key: "orgDesc",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.status == "3" ? "#000" : "red",
                                },
                            },
                            params.row.status == "3" ? "已注销" : "待注销"
                        )
                    },
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                        display:params.row.status == 3 ? 'none' : 'inline-block'
                                    },
                                    on: {
                                        click: () => {
                                            this.logoff(params.row)
                                        },
                                    },
                                },
                                "注销"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                        display:params.row.status == 3 ? 'none' : 'inline-block'
                                    },
                                    on: {
                                        click: () => {
                                            this.revocationApply(params.row.id)
                                        },
                                    },
                                },
                                "取消申请"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
        }
    },

    methods: {
        //分页
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        tableSelect(val){
            console.log(val)
        },
        searchBtn(){
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        resetBtn(){
            this.page = 1
            this.pageSize = 20
            this.search.userName = ''
            this.search.mobile = ''
            this.search.status = '全部'
            this.getList()
        },
        getList(){
            this.loading = true
            this.$get('/auth/api/lfuser/userRemove/listRemoveApplyByPage',{
                userName:this.search.userName,
                mobile:this.search.mobile,
                status:this.search.status == '全部'?'':this.search.status,
                page:this.page,
                pageSize:this.pageSize
            }).then( res => {
                this.loading = false
                if(res.code == 200 && res.dataList){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.pageSize = res.currentPage
                }else{
                    this.$MEssage.error({
                        content:'获取数据失败',
                        background:true
                    })
                }
            })
        },
        logoff(data){
            this.$Modal.confirm({
                title: "温馨提示",
                loading:true,
                content:`是否注销 <span style='color:red'>${data.userName}</span> 该账号？`,
                onOk:() => {
                this.$post('/auth/api/lfuser/userRemove/removeCustomer',{
                    custGlobalId:data.custGlobalId,
                    staffUserId:parent.vue.loginInfo.userinfo.id,
                    id:data.id
                }).then(res => {
                    this.$Modal.remove();
                    if(res.code == 200){
                        this.$Message.success({
                            content:'注销成功',
                            background:true
                        })
                        this.getList()
                    }else{
                        this.$Message.error({
                            content:'注销失败',
                            background:true
                        })
                    }
                })            
                }
            })
        },
        revocationApply(id){
            this.$post('/auth/api/lfuser/userRemove/revocationApply',{
                id,
            }).then( res => {
                if(res.code == 200){
                    this.$Message.success({
                        content:'取消成功',
                        background:true
                    })
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'取消失败',
                        background:true
                    })
                }
            })
        },
    },
    created() {
        this.getList()
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
</style>